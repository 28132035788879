
function StaffPicksElement(element) {
    this.containerElement = element;
    this.leftButton = null;
    this.rightButton = null;
    this.scrollContainer = null;
    this.hasClicked = false;

    this.initialize = function() {
        this.leftButton = this.containerElement.getElementsByClassName('js-staffpick__leftbutton')[0]
        this.leftButton.addEventListener('click', this.onLeftButtonClick.bind(this));

        this.rightButton = this.containerElement.getElementsByClassName('js-staffpick__rightbutton')[0]
        this.rightButton.addEventListener('click', this.onRightButtonClick.bind(this));

        this.scrollContainer = this.containerElement.getElementsByClassName('js-staffpick__scrollcontainer')[0]
    };

    this.onRightButtonClick = function() {
        this.scrollContainer.scrollTo({top: 0, left: this.scrollContainer.scrollLeft + this.scrollContainer.offsetWidth, behavior: 'smooth'});
    };

    this.onLeftButtonClick = function() {
        this.scrollContainer.scrollLeft -= this.scrollContainer.offsetWidth;
        this.scrollContainer.scrollTo({top: 0, left: this.scrollContainer.scrollLeft - this.scrollContainer.offsetWidth, behavior: 'smooth'});
    };
}

document.addEventListener("DOMContentLoaded", function(event) {
    let staffpickElement = document.getElementsByClassName('js-staffpick');
    for (var i = staffpickElement.length - 1; i >= 0; i--) {
        let staffpicComponent = new StaffPicksElement(staffpickElement[i]);
        staffpicComponent.initialize();
    }
});