import {LitElement, html, css} from 'lit';

export class ProjectOrderElement extends LitElement {
    static get styles() {
        return css`
            :host {
                width: 100%;
            }
            .search-options_sort {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                align-content: stretch;
            }
            
            .search-options_sort-title {
                font-size: 1.3em;
                margin-right: 0.5em;
                font-weight: bold;
            }

            .search-options_sort select {
                width: 100%;
                font-size: 1.2em;
            }
            input, select, button, textarea {
                border: 1px solid #000;
                background-color: var(--input-bg-color,#222222);
                color: var(--input-color,#FFF);
                padding: 8px 12px;
            }

            @media only screen and (min-width: 920px) {
                :host {
                    width: auto;
                    min-width: 420px;
                }
                .search-options_sort select {
                    width: auto;
                    font-size: 1.2em;
                }
            }
        `;
    }
    
    static get properties() {
        return {
            orderOptions: { type: Array },
            orderBy: { type: String }
        };
    }

    

    constructor() {
        super();
        this.orderBy = '-last_file_date';
        this.orderOptions = [
            {
                'order_key': '-created',
                'description': 'Date Added'
            },
            {
                'order_key': 'created',
                'description': 'Date Added (Ascending)'
            },
            {
                'order_key': '-published_date',
                'description': 'Published Date'
            },
            {
                'order_key': 'published_date',
                'description': 'Published Date (Ascending)'
            },
            {
                'order_key': '-last_file_date',
                'description': 'Last Updated'
            },
            {
                'order_key': 'last_file_date',
                'description': 'Last Updated (Ascending)'
            },
            {
                'order_key': '-views',
                'description': 'Views'
            },
            {
                'order_key': 'views',
                'description': 'Views (Ascending)'
            },
            {
                'order_key': '-popularity',
                'description': 'Hot'
            },
            {
                'order_key': 'popularity',
                'description': 'Hot (Ascending)'
            }
        ];
    }

    getOrderByTemplate(item) {
        if (this.orderBy === item.order_key) {
            return html`
                <option value="${item.order_key}" selected=""}>${item.description}</option>
            `;
        }
        return html`
            <option value="${item.order_key}">${item.description}</option>
        `;
    }

    _changeOrder(e) {
        this.orderBy = e.target.value;
        this.dispatchEvent(new CustomEvent('orderchange', {
            bubbles: true,
            composed: true,
            detail: {
                orderBy: this.orderBy
            }
        }));
    }

    render() {
        return html`
            <div class="search-options_sort">
                <div class="search-options_sort-title">Sort by:</div>
                <select name="order_by" id="id_order_by" @change="${this._changeOrder}">
                    ${this.orderOptions.map(
                        (item) => {
                            return this.getOrderByTemplate(item)
                        }
                    )}
                </select>
            </div>
        `;
    }
}

window.customElements.define('project-order-element', ProjectOrderElement);