import {LitElement, html, css} from 'lit';

export class TagElement extends LitElement {
    static get styles() {
        return css`
            :host {
                display: flex;
                padding:0;
                margin:0;
                color: #959595;
            }
            .tag-element {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                background-color: #333;
                margin: 0.4em;
                padding: 0.5em;
                border: 1px solid #444;
                border-radius: 3px;
                font-weight: bold;
                cursor: pointer;
            }
            .tag-element--universe {
                background-color: #1e3416;
            }
            .tag-element--character {
                background-color: #17334a;
            }
            .tag-element--software {
                background-color: #350606;
            }
            .tag-element--misc {
                background-color: #333;
            }
            .tag-element-type {
                margin-right: 0.3em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }
            .tag-element-name {
                color: #FFF;
                margin-right: 0.3em;
            }
            .tag-element-count {
                margin-right: 0.3em;
            }
            .tag-element-delete {
                color: #aaa;
            }
            .tag-element:hover .tag-element-delete {
                color: #FFF;
            }
            .tag-element-type svg {
                width: 16px;
                height: 16px;
            }
        `;
    }
    
    static get properties() {
        return {
            id: { type: Number },
            name: { type: String },
            type: { type: String },
            count: { type: Number },
        };
    }

    

    constructor() {
        super();
        this.id = 0;
        this.name = '';
        this.type = '';
        this.count = 0;
    }

    getIcon() {
        if (this.type === 'universe') {
            return html`
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="globe-europe" class="svg-inline--fa fa-globe-europe fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                    <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm200 248c0 22.5-3.9 44.2-10.8 64.4h-20.3c-4.3 0-8.4-1.7-11.4-4.8l-32-32.6c-4.5-4.6-4.5-12.1.1-16.7l12.5-12.5v-8.7c0-3-1.2-5.9-3.3-8l-9.4-9.4c-2.1-2.1-5-3.3-8-3.3h-16c-6.2 0-11.3-5.1-11.3-11.3 0-3 1.2-5.9 3.3-8l9.4-9.4c2.1-2.1 5-3.3 8-3.3h32c6.2 0 11.3-5.1 11.3-11.3v-9.4c0-6.2-5.1-11.3-11.3-11.3h-36.7c-8.8 0-16 7.2-16 16v4.5c0 6.9-4.4 13-10.9 15.2l-31.6 10.5c-3.3 1.1-5.5 4.1-5.5 7.6v2.2c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8s-3.6-8-8-8H247c-3 0-5.8 1.7-7.2 4.4l-9.4 18.7c-2.7 5.4-8.2 8.8-14.3 8.8H194c-8.8 0-16-7.2-16-16V199c0-4.2 1.7-8.3 4.7-11.3l20.1-20.1c4.6-4.6 7.2-10.9 7.2-17.5 0-3.4 2.2-6.5 5.5-7.6l40-13.3c1.7-.6 3.2-1.5 4.4-2.7l26.8-26.8c2.1-2.1 3.3-5 3.3-8 0-6.2-5.1-11.3-11.3-11.3H258l-16 16v8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-20c0-2.5 1.2-4.9 3.2-6.4l28.9-21.7c1.9-.1 3.8-.3 5.7-.3C358.3 56 448 145.7 448 256zM130.1 149.1c0-3 1.2-5.9 3.3-8l25.4-25.4c2.1-2.1 5-3.3 8-3.3 6.2 0 11.3 5.1 11.3 11.3v16c0 3-1.2 5.9-3.3 8l-9.4 9.4c-2.1 2.1-5 3.3-8 3.3h-16c-6.2 0-11.3-5.1-11.3-11.3zm128 306.4v-7.1c0-8.8-7.2-16-16-16h-20.2c-10.8 0-26.7-5.3-35.4-11.8l-22.2-16.7c-11.5-8.6-18.2-22.1-18.2-36.4v-23.9c0-16 8.4-30.8 22.1-39l42.9-25.7c7.1-4.2 15.2-6.5 23.4-6.5h31.2c10.9 0 21.4 3.9 29.6 10.9l43.2 37.1h18.3c8.5 0 16.6 3.4 22.6 9.4l17.3 17.3c3.4 3.4 8.1 5.3 12.9 5.3H423c-32.4 58.9-93.8 99.5-164.9 103.1z"></path>
                </svg>
            `;
        }
        if (this.type === 'character') {
            return html`
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="female" class="svg-inline--fa fa-female fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                    <path fill="currentColor" d="M128 0c35.346 0 64 28.654 64 64s-28.654 64-64 64c-35.346 0-64-28.654-64-64S92.654 0 128 0m119.283 354.179l-48-192A24 24 0 0 0 176 144h-11.36c-22.711 10.443-49.59 10.894-73.28 0H80a24 24 0 0 0-23.283 18.179l-48 192C4.935 369.305 16.383 384 32 384h56v104c0 13.255 10.745 24 24 24h32c13.255 0 24-10.745 24-24V384h56c15.591 0 27.071-14.671 23.283-29.821z"></path>
                </svg>
            `;
        }
        if (this.type === 'software') {
            return html`
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cube" class="svg-inline--fa fa-cube fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M239.1 6.3l-208 78c-18.7 7-31.1 25-31.1 45v225.1c0 18.2 10.3 34.8 26.5 42.9l208 104c13.5 6.8 29.4 6.8 42.9 0l208-104c16.3-8.1 26.5-24.8 26.5-42.9V129.3c0-20-12.4-37.9-31.1-44.9l-208-78C262 2.2 250 2.2 239.1 6.3zM256 68.4l192 72v1.1l-192 78-192-78v-1.1l192-72zm32 356V275.5l160-65v133.9l-160 80z"></path>
                </svg>
            `;
        }
        if (this.type === 'misc') {
            return html`
                <svg  aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tag" class="svg-inline--fa fa-tag fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="#959595" d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L418.7 226.7C443.7 251.7 443.7 292.3 418.7 317.3L285.3 450.7C260.3 475.7 219.7 475.7 194.7 450.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z"/>
                </svg>
            `;
        }
    }

    _removeTag() {
        this.dispatchEvent(new CustomEvent('removetag', {
            bubbles: true,
            composed: true,
            detail: {
                id: this.id,
                type: this.type
            }
        }));
    }

    render() {
        return html`
            <div class="tag-element tag-element--${this.type}" @click="${this._removeTag}">
                <span class="tag-element-type">${this.getIcon()}</span>
                <span class="tag-element-name">${this.name}</span>
                <span class="tag-element-count"></span>
                <span class="tag-element-delete">🗑</span>
            </div>
        `;
    }
}

window.customElements.define('tag-element', TagElement);